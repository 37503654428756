<template>
    <div>
        <Header></Header>
        <div class="content screen_content">
            <!-- <div class="screen_del">清除筛选</div> -->
            <!-- <div class="screen_list">
                <div class="screen_list_left">
                    形式
                </div>
                <div class="screen_list_right">
                    <div class="right_item this">
                        全部
                    </div>
                    <div class="right_item">
                        免费课
                    </div>
                    <div class="right_item">
                        超级会员免费
                    </div>
                </div>
            </div> -->
            <div class="screen_list">
                <div class="screen_list_left">
                    方向
                </div>
                <div class="screen_list_right">
                    <div class="right_item" :class="{this:current == index}" @click="clicks(index)" v-for="(item,index) in list" :key="index">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="screen_list" v-if="list2.length>0">
                <div class="screen_list_left">
                    分类
                </div>
                <div class="screen_list_right">
                    <div class="right_item" :class="{this:current2 == index}" @click="clickss(index)" v-for="(item,index) in list2" :key="index">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="list_content content">
            <div class="list_screen spjz">
                <!-- <div class="list_screen_item this">
                    热门
                </div>
                <div class="list_screen_item">
                    最新
                </div>
                <div class="list_screen_item">
                    价格
                </div>
                <div class="input-wrapper spjz">
                    <input type="text" placeholder="搜索课程">
                    <div class="iconfont icon-sousuo1"></div>
                </div> -->
                <div class="list_screen_item">
                    课程列表
                </div>
                <div class="list_screen_right">
                    共<span>{{ count }}</span>个结果
                </div>
            </div>
            <div class="list">
                <div class="list_item tran" v-for="(item,index) in video_list" :key="index" @click="link_detail(item.id,item.type,item.vipSet)">
                    <div class="item_left">
                        <img :src="item.showVideoStatus!=3?(item.photoUrl || ''):item.memberPhotoReport.photoUrl" alt="" class="tran">
                        <div class="img_mask tran spczjz" >
                            <span class="iconfont icon-bofang tran"></span>
                        </div>
                        <div class="item_vip" v-if="item.vipSet==1&&!version">VIP</div>
                        <div class="item_vip" v-else-if="item.vipSet==2&&!version">付费</div>
                        <div class="item_vip" v-else>免费</div>
                        <div class="item_date" v-if="item.showVideoStatus!=3&&!version&&item.viewType==1">{{item.videoLength ? changSecond(item.videoLength||'00:00') : '00:00'}}</div>
                    </div>
                    <div class="item_right">
                        <div class="item_title chaochu">
                            {{item.showVideoStatus!=3?(item.videoTitle || ''):item.memberPhotoReport.reportText}}
                        </div>
                        <div class="item_info caochu">
                            {{item.showVideoStatus!=3?(item.videoDetails || ''):item.memberPhotoReport.profiles}}
                        </div>
                        <div class="item_money">
                            <span class="item_price" v-if="item.vipSet==2&&!version">￥{{item.price || 0}}</span>
                            <span v-if="item.showVideoStatus==3">
                                {{(item.memberPhotoReport.praiseCount || 0)>10000?changeUnits(item.memberPhotoReport.praiseCount):(item.memberPhotoReport.praiseCount || 0)}} 喜欢
                            </span>
                            <span v-else>
                                {{(item.viewedNum || 0)>10000?changeUnits(item.viewedNum):(item.viewedNum || 0)}} 学习 · {{(item.praiseCount || 0)>10000?changeUnits(item.praiseCount):(item.praiseCount || 0)}} 喜欢
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <nodata v-if="video_list.length == 0"></nodata>
            <div class="spczjz mt10">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="video_current"
                :page-size="12"
                hide-on-single-page
                layout="prev, pager, next, jumper"
                :total="count">
                </el-pagination>
            </div>
            <gap height="50"></gap>
            <Homefooter ></Homefooter>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { sourc,sourc2,pageVideo,selectByPid } from "@/apiUrl/index.js";
    export default {
        computed: {
            ...mapGetters(["token"]),
        },
        data() {
            return {
                sourc,sourc2,
                list:[],
				current:0,
				video_current:1,
				videoType:'',
				videoTypeId:'',
				video_list:[],
				isOK:true,
				type:1,
				icon_name:'',
				lwltab:true,
				list2:[],
				current2:0,
				id:'', // 一级分类id
				twoType:'', // 二级分类id
				videoTypes:'', // 三级分类id
                count:0, // 总数
                version:this.$store.state.version,
            };
        },
        created() {
            var {videoType,twoType,id,icon_name,videoTypeId} = this.$route.query;
			this.id = id || '';
			this.icon_name = icon_name || '每日案例';
			this.videoTypeId = videoTypeId || '';
			if(videoType && twoType) { // 从分类中进来
				this.twoType = twoType;
				this.videoTypes = videoType;
				this.getChildData();
			} else {
				this.getdata();
				this.gettabs();
			}
        },
        methods: {
            // 视频详情
            link_detail(id,type,vipSet) {
                if(type==1){
                    this.$link('/video_detail?id=' + id + '&mode=video')
				}else if(type==2){
					if(vipSet==2){
                        this.$link('/video_detail2?id=' + id + '&mode=video')
					}else{
						this.$link('/video_detail1?id=' + id + '&mode=video')
					}
				}
                
            },
            handleCurrentChange(val) {
                this.video_current = val;
                this.video_list = [];
                this.getdata();
            },
            handleSizeChange(val) {
                this.video_current = val;
                this.video_list = [];
                this.getdata();
            },
            changSecond(e) { //转换成秒
				return this.$secondToMinutes(e)
			},
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            getChildData () {
				this.gettabs().then(res => {
					if(this.list && this.list.length ) {
						this.list.some((item,index) => { // 选中二级分类
							if(item.id == this.twoType) {
								this.current = index
								return true
							}
						})
						selectByPid({pid:this.twoType}).then(res => { // 选中三级分类
							if(res.code == 200) {
								this.list2 = res.data;
								if(this.list2 && this.list2.length ) {
									this.list2.some((item,index) => { // 选中二级分类
										if(item.id == this.videoTypes) {
											this.current2 = index
											return true
										}
									})
								}
								this.getdata();
							}
						})
					}
				});
			},
			async gettabs() {
				return selectByPid({pid:this.id}).then(res => {
					if(res.code == 200) {
						var teacherMenuList = [{name:'全部',id:''}]
						this.list = teacherMenuList.concat(res.data);
					}
				})
			},
			getdata(){
				let form = {oneType:this.id,current:this.video_current,size:12,twoType:this.twoType,videoType:this.videoTypes}
				if(form.videoType) {
					form.twoType = '';
					form.oneType = '';
				} else if(form.twoType) {
					form.oneType = '';
					form.videoType = '';
				} else {
					form.twoType = '';
					form.videoType = '';
				}
				pageVideo(form).then((res)=>{
					if(res.code==200){
						var arr = res.data.list;
						this.video_list = this.video_list.concat(arr);
                        this.count = res.data.count || 0;
					}else{
						this.$msg(res.message);
					}
				})
			},
            clicks(e){
				this.current = e;
				this.video_list = [];
				this.isOK = true;
				this.video_current = 1;
				this.twoType = this.list[this.current].id||'';
				this.videoTypes = '';
                console.log(this.current)
                if(this.current == 0) {
                    this.list2 = [];
                } else {
                    selectByPid({pid:this.twoType}).then(res => {
                        if(res.code == 200) {
                            if(res.data.length > 0) {
                                var list = [{name:'全部',id:''}]
                                this.list2 = list.concat(res.data);
                                this.current2 = 0;
                            }
                            
                        }
                    })
                }
				
				this.getdata();
			},
			clickss(e){
				this.current2 = e;
				this.video_list = [];
				this.isOK = true;
				this.video_current = 1;
				this.videoTypes = this.list2[this.current2].id||'';
				
				this.getdata();
			},
        },
    };
</script>
<style lang="less" scoped>
    .screen_content{
        background-color: #fff;
        border-radius: 6px;
        margin-top: 16px;
        padding: 20px 20px 16px 20px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 10px;
    }
    .screen_list{
        display: flex;
        margin-bottom: 16px;
    }
    .screen_list:last-child{
        margin-bottom: 0;
    }
    .screen_list_left{
        display: inline-block;
        text-align: center;
        width: 48px;
        margin-top: 1px;
        height: 28px;
        line-height: 28px;
        background: #f5f7f8;
        color: #1c1d30;
        font-weight: 500;
        border-radius: 17px 0 17px 17px;
        margin-right: 16px;
    }
    .screen_list_right{
        width: 1000px;
    }
    .right_item{
        cursor: pointer;
        display: block;
        float: left;
        height: 28px;
        line-height: 28px;
        padding: 0 8px;
        border: 0;
        outline: 0;
        border-radius: 17px;
        margin-right: 8px;
        margin-bottom: 8px;
        color: var(--font-color);
    }
    .right_item:hover{
        color: var(--color);
    }
    .right_item.this{
        color: var(--color);
        background-color: #f3f6ff;
    }
    .screen_del{
        position: absolute;
        top: 20px;
        right: 16px;
        line-height: 28px;
        cursor: pointer;
        box-shadow: none;
        height: 32px;
        padding: 0 15px;
        color: var(--font-color);
    }
    .list_content{
        background-color: #fff;
        padding: 16px 24px;
        box-sizing: border-box;
    }
    .list_screen{
        position: relative;
        margin-bottom: 12px;
        padding-left: 8px;
    }
    .list_screen_item{
        margin-right: 36px;
        font-size: 16px;
        position: relative;
        line-height: 24px;
        color: var(--font-color);
    }
    .list_screen_item.this{
        color: #1c1d30;
    }
    .list_screen_item.this:after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background: #1c1d30;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -3;
    }
    .input-wrapper{
        display: inline-block;
        position: relative;
        input{
            width: 252px;
            height: 32px;
            border-radius: 21px;
            padding: 0 24px 0 16px;
            background-color: #fff;
            border: 1px solid #dcdee2;
            box-sizing: border-box;
        }
        input:focus {
            border: 1px solid var(--color) !important;
        }
        input:focus-visible {
            outline: none;
        }
        .icon-sousuo1 {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 22px;
            text-align: center;
            color: #808695;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 3;
        }
    }
    .list_screen_right{
        position: absolute;
        right: 0px;
        color: #515a6e;
        span{
            color: #222;
        }
    }
    .list{
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }
    .list_item{
        width: 568px;
        box-sizing: border-box;
        padding: 12px 8px;
        height: 160px;
        cursor: pointer;
        display: flex;
        border-radius: 4px;
        overflow: hidden;
        border-radius: 6px;
    }
    .list_item:hover{
        background-color: #f7f7fc;
        .img_mask{
            background-color: rgba(0,0,0,0.4);
        }
        .icon-bofang{
            opacity: 1;
            font-size: 40px;
        }
        img{
            transform:scale(1.1)
        }
    }
    .item_left{
        width: 220px;
        height: 136px;
        margin-right: 16px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .img_mask{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0);
        z-index: 1;
    }
    .icon-bofang{
        font-size: 100px;
        color: #fff;
        opacity: 0;
    }
    .item_right{
        width: 316px;
    }
    .item_title{
        font-size: 16px;
        color: #222226;
        height: 20px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .item_info{
        font-size: 12px;
        margin-bottom: 8px;
        color: #999aaa;
        line-height: 22px;
        height: 44px;
        margin-top: 8px;
    }
    .item_money{
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #555666;
        line-height: 28px;
        text-shadow: 0 4px 8px rgb(0 0 0 / 5%);
        padding-top: 10px;
    }
    .item_price{
        font-size: 16px;
        font-weight: 700;
        color: #fc5531;
        line-height: 28px;
        margin-right: 8px;
    }
    .item_vip{
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 12px;
        border-radius: 3px;
        background-color: #f6bd74;
        color: #9c6a27;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .item_date{
        height: 15px;
        font-size: 12px;
        line-height: 15px;
        padding: 0 5px;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 10px;
    }
</style>
  